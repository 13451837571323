// calculators input types 
export const InputTypes = {
    number: "number",
    numberComma: "numberComma",
    numberDollar: "numberDollar",
    numberMonths: "numberMonths",
    numberPercent: "numberPercent",
    date: "date",
    select: "select",
    height: "height",
    text: "text",
    HTML: "HTML",
    Resistance: "Resistance"
}