import React, { Component } from "react";
export default class ProgressLoader extends Component {

    render() {
        const { isLoading } = this.props;
        return (
            <div className={`overlay-screen ${isLoading ? 'visible' : 'hidden'}`}>
                <div className="lds-dual-ring"></div>
            </div>
        );
    }
}
