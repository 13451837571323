export const calcIds = {

    // auto Calculators
    autoLoan: "loan-calculator",
    autoBiweekly: "bi-weekly-loan",
    buyvsLease: "lease-buy",
    autoLowInterest: "loan-comparison",
    autoRebate: "rebate",
    autoPayoff: "loan-payoff",
    autoEquityLoan: "heloc",
    cUAutoRebate: "cudl",

    //business Calculators
    cashFlow: "cash-flow",
    capital: "working-capital",
    profitMargin: "margin",
    debtService: "commercial-loan",
    repoReal: "repo-real",
    breakEven: "breakeven-calculator",
    debtService2: "debt-service-coverage-ratio",
    repoPersonalInstall: "repo-personal-install",
    inventory: "inventory",
    ratios: "financial-ratios",
    repoPersonalDP: "repo-personal-d-p",
    bizBuyvsLease: "equipment-lease",

    //dept Calculators
    bizConsolidate: "debt-consolidation",
    debtRolldown: "reduce-credit-card",
    payoffCC: "credit-card-payoff",
    debtPayoff2: "debt-payoff2",

    //insurance Calculators
    disability: "disability",
    hSAContribution: "hsa-contributions",
    longtermCare: "long-term-care",
    humanLifeValue: "human-life-value-calculator",
    immediateAnnuity: "immediate-annuity",
    hSAGoal: "h-s-a-goal",
    hSASavings: "hsa-plan",
    lifeCalculator: "life-calculator",
    lifeInsurance: "life-insurance",

    //investment Calculators
    assetAllocator: "asset-allocation-calculator",
    investmentVariables: "goals",
    investmentLoan: "property-loans",
    presentValueAmount: "present-value-formula",
    // stockGrants: "options",
    futureContracts: "future-contracts",
    taxEquivYield: "bond-yield",
    rEITTaxEquivYield: "reit",
    recoverTime: "capital-recovery",
    fundExpense: "mutual-fund-calculator",
    stockOptions: "options-profit",
    investmentReturn: "roi",
    compareFees: "compare-fees",
    investmentDistribution: "planning",
    annualReturnAmount: "annual-rate-return",
    futureValueAmount: "future-value-formula",
    presentValue: "present-value-calculator",
    futureValue: "future-value-calculator",
    annualReturn: "rate-return-calculator",
    iRRReturn: "irr",
    investmentDistribution2: "income",

    //loan Calculators
    compareLoan: "comparison-calculator",
    balloonLoan: "balloon-payment-calculator",
    creditLine: "home-equity-calculator",
    enhancedLoan: "enhanced-loan",
    simpleLoan: "amortization-calculator",
    paymentLine: "heloc-calculator",
    payoffLine: "credit-line-calculator",
    existingLoan: "balance-calculator",

    //mortgage Calculators
    mortgageApr: "apr-calculator",
    existingBiweekly: "biweekly-calculator",
    mortgageAdjustable: "adjustable-calculator",
    mortgageAprAdjustable: "arm-apr-calculator",
    mortgageArmvsFixed: "arm-fixed-rate",
    mortgageBalloon: "balloon",
    mortgageBlend: "blended-rate",
    mortgageFixedvsIO: "fixed-rate",
    mortgageTaxes: "tax-deduction",
    mortgageInterestOnly: "interest-only",
    mortgageLoan: "payment",
    mortgageLoan2: "piti",
    mortgagePoints: "points-calculator",
    fHAMortgageQualifier: "fha-loan",
    mortgageBiweekly: "biweekly",
    // mortgage15_vs_30: "15-vs-30",
    mortgageComparison: "comparison",
    //shouldIRefi: "comparison",
    mortgageQualifier: "qualifier",
    mortgageFixedvsLibor: "libor",
    reverseMortgage: "reverse",
    mortgageIOAdjustable: "arm-calculator",
    mortgageMax: "maximum-calculator",
    mortgageIncome: "required-income",
    mortgageRentvsBuy: "rent-buy",
    mortgageRefinance: "refinance",
    mortgagePayoff: "payoff",
    mortgageHomebuyerSavings: "down-payment",

    //personal Calculators
    lifeTime: "life-expectancy",

    //retirement Calculators
    pensionPlan: "pension-plan",
    PensionCalculator: "pension-calculator",
    socialSecurity: "social-security-benefits",
    retirementWithdrawal: "plan-withdrawal-aftertax",
    retirementWithdrawal457: "457-plan",
    retirementNestegg: "nestegg",
    rollover401k: "401k-save",
    retire403b: "403b",
    retireShort: "shortfall",
    rothIRA: "roth-ira-calculator",
    // retireBeneficiary: "beneficiary",
    // stretchIRA: "inherited-ira-rmd-calculator",
    retireBeneficiary: "inherited-ira-rmd-calculator",
    stretchIRA: "beneficiary",
    accelPayrollSavings: "accel-payroll-savings",
    retire401kProfitShare: "401k",
    rothvsTraditional403b: "roth-403b",
    noSocial: "social-security",
    retire72T: "72t-distribution",
    individual401kContribution: "self-employed-retirement-plans",
    retire457: "457",
    rolloverIRA: "ira-withdrawal",
    rothTransfer: "roth-ira-conversion",
    retirementPlan: "calculator",
    retire72Alt: "72t",
    retirementDistribution: "savings",
    retireDistrib3: "required-minimum-distribution",
    retireDistrib: "rmd-calculator",
    retire401k: "401k-calculator",
    retireSolo401k: "solo-401k",
    compare401k2: "roth-traditional-401k",
    rothvsTraditional457: "roth",
    retirementPlan4: "income-planning",
    retirementIncome: "income-calculator",
    regularIRA: "ira-calculator",
    rothvsTraditional401k: "roth-401k",

    //saving Calculators
    checkBook: "checkbook-balancer",
    collegeSavings: "college-plan",
    viceSaver: "frivolous-spending",
    spendLess: "budget-planner",
    compoundInterest: "compound-interest-calculator",
    savingsVariables: "inflation-rate",
    compoundSavings: "plan-calculator",
    cUDeposit: "cd-calculator",
    waitCost: "planner",
    savings: "goals-calculator",
    compareSavings: "apy-calculator",
    millionaire: "millionaire-calculator",
    lunchSaver: "lunch-saver",
    distribSavings: "withdrawal-calculator",

    //tax Calculators
    estatePlan: "estate-calculator",
    payrollHourly: "paycheck-calculator",
    taxSelfEmployment: "self-employment-calculator",
    earnedIncomeCredit: "earned-income-credit",
    taxMargin: "marginal-rate-calculator",
    taxEZ1040: "1040ez",
    payrollGross: "payroll-calculator",
    payroll125: "125-cafeteria-plan",
    payroll: "paycheck-deductions",
    payroll457: "irs-withholding-calculator",
    likeKindExchange: "1031-exchange",

    // Math Calculators
    areaCalculatorM: 'area-calculator-m',
    dateDurationCalculator: 'date-duration-calculator',
    percentageCalculator: 'percentage-calculator',
    percentErrorCalculator: 'percent-error-calculator',
    binaryCalculator: 'binary-calculator',
    exponentialFunctionCalculator: 'exponential-functions',
    timeDurationCalculator: 'time-duration-calculator',
    halfLifeCalculator: 'half-life-calculator',
    logCalculator: 'log-calculator',
    sequenceCalculator: 'sequence-calculator',
    quadraticFormulaCalculator: 'quadratic-formula-calculator',
    volumeCalculator: 'volume-calculator',
    fractionCalculator: 'fraction-calculator',
    densityFormulaCalculator: 'density-formula',

    //Pregnancy Calculators
    dueDate: "due-date",
    conception: "conception",
    plannerCalculator: "planner-calculator",
    fertilityOvulationCalendar: "fertility-ovulation-calendar",

    //Other Calculators
    ageCalculator: "age-calculator",
    concrete: "concrete",
    gdp: "gdp",
    heatIndex: "heat-index",
    height: "height",
    fuelCost: "gas-calculator",
    horsepowerEngine: "horsepower",
    power: "power",
    ohmsLaw: "ohms-law",
    roofing: "roofing",
    squareFootageCalculator: "square-footage-calculator",
    love: "love",
    tileCalculator: "tile-calculator",
    timeZoneConverter: "time-zone-converter",
    tipCalculator: "tip-calculator",
    windChill: "wind-chill",

    //Physics Calculators
    accelerationAngularCalculator: "angular-acceleration",
    velocityAngularCalculator: "angular-velocity",
    capacitanceCalculator: "capacitance",
    currentDensityCalculator: "current-density",
    viscosityDynamicCalculator: "dynamic-viscosity",
    electricFieldStrengthCalculator: "electric-field",
    energyCalculator: "energy",
    flowCalculator: "flow-rate",
    inductanceCalculator: "inductance",
    viscosityKinematicCalculator: "kinematic-viscosity",
    flowMassCalculator: "mass-flow-rate",
    massFluxDensityCalculator: "mass-flux",
    concentrationMolarCalculator: "molarity",
    momentOfInteriaCalculator: "moment-inertia",
    momentOfForceCalculator: "newton-meter",
    radiationCalculator: "radioactive-decay",
    electricResistivityCalculator: "resistivity-units",
    surfaceChargeDensityCalculator: "surface-charge-density",
    torqueCalculator: "torque",
    surfaceTensionCalculator: "surface-tension",
    resistance: "resistance",
    magneticFluxDensityCalculator: "magnetic-field",
    magneticFieldStrengthCalculator: "magnetic-field-strength",
    magneticFluxCalculator: "magnetic-flux",

    //Thermodynamics Calculators
    thermalConductivityCalculator: "thermal-conductivity",
    thermalExpansionCalculator: "thermal-expansion",
    thermalResistanceCalculator: "thermal-resistance",
    fuelEfficiencyMassCalculator: "heat-combustion",
    heatFluxDensityCalculator: "heat-flux",
    heatTransferCoefficientCalculator: "heat-transfer-coefficient",
    specificHeatCapacityCalculator: "specific-heat-capacity",

    //conversion
    angleCalculator: "radians-degrees",
    areaCalculatorConv: "area",
    forceCalculator: "newton-units",
    fuelEconomy: "fuel-economy",
    lengthCalculator: "mm-inches",
    numbersCalculator: "hex-decimal",
    powerCalculator: "watts-volts",
    pressureCalculator: "mpa-unit",
    timeCalculator: "time",
    fpsToMpH: "fps-mph",
    volumeCalculatorConv: "ml-to-l",
    volumeDryCalculator: "volume-dry-calculator",
    weightCalculator: "grams-pounds",
    chargeCalculator: "nc-to-c",   //Electricity
    currentCalculator: "current-calculator",
    electricConductanceCalculator: "siemens-unit",
    electricConductivityCalculator: "electric-conductivity-calculator",
    electricPotentialCalculator: "electric-potential-calculator",
    electricResistanceCalculator: "electric-resistance-calculator",
    linearChargeDensityCalculator: "linear-charge-density-calculator",
    linearCurrentDensityCalculator: "linear-current-density-calculator",
    volumeChargeDensityCalculator: "volume-charge-density-calculator",
    accelerationCalculator: "acceleration-calculator", //Engineering
    densityCalculator: "density-conversion",
    specificVolumeCalculator: "specific-volume-calculator",
    concentrationSolutionCalculator: "concentration-solution-calculator",//fluids
    flowMolarCalculator: "flow-molar-calculator",
    permeabilityCalculator: "permeability-calculator",
    frequencyResourcesCalculator: "frequency-resources-calculator",//frequency
    fuelEfficiencyVolumeCalculator: "fuel-efficiency-volume-calculator",//heat
    heatDensityCalculator: "heat-density-calculator",
    temperatureIntervalCalculator: "temperature-interval-calculator",
    kbToMB: "kb-mb",//ITAndTypography
    mbpsKbps: "mbps-kbps",
    resolutionCalculator: "resolution",
    typographyCalculator: "pixels-inches",
    frequencyWavelengthCalculator: "megahertz-hertz",//Light
    illuminationCalculator: "illumination-calculator",
    luminanceCalculator: "luminance-calculator",
    luminousIntensityCalculator: "luminous-intensity",//Magnetism
    magnetomotiveForceCalculator: "magnetomotive-force-calculator",
    prefixesCalculator: "metric-prefixes",//Misc
    volumeLumberCalculator: "cubic-meter",
    radiationAbsorbedDoseCalculator: "radiation-absorbed-dose-calculator",//Radiology
    radiationActivityCalculator: "radiation-activity-calculator",
    radiationExposureCalculator: "radiation-exposure-calculator",
    ArmyBodyFatCalc: "army-body-fat-calculator",//Health
    BmiCalc: "bmi-calculator",
    BmrCalc: "bmr-calculator",
    BodyFatcalc: "body-fat-calculator",
    CalorieCalc: "calorie",
    IdealWeightCalc: "ideal-weight",
    LeanBodyMassCalc: "lean-body-mass-calculator",
    WeightWatchersCalc: "weight-watchers-points",
}