import * as types from '../types/actionTypes';
import { isNotEmpty } from '../utilities/Utils';

const initialState = {
  showDrawer: false,
  showAppMenu: false,
  showLinkShare: false,
  isLoading: false,
  searchKeys: '',
  activeRoute: 'Questionnaire',
  activeItemIndex: 0,
  activeItemIndexHeaderIndecator: 0,
  showCalculatorDetails: false,
  questionKey: 0,
  questionListKey: 0,
  questionnaireListData: [],
  resultsListData: {
    chartListData: [],
    gridListData: []
  },
  bestResultsListData: [],
  headingInformationData: [],
  resultString: '',
  isError: false,
  showWelcomeMessage: false,
  welcomeMessage: '',
  activeResultItem: {},
  showResult: false,
  fetchDataFromApi: true,
  InputList: [],
  inputKeyPressCount: 0,
  reportData: false,
  showContactUs: false,
  showAboutUs: false,
  showPrivacy: false,
  //pdf function
  showEmailDialog: false,
  showPdfPreviewDialog: false,
  name: '',
  email: ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_DRAWER:
      return {
        ...state,
        showDrawer: action.payload
      }
    case types.UPDATE_QUESTIONNAIRES:

      return {
        ...state,
        questionListKey: state.questionListKey + 1,
        activeItemIndexHeaderIndecator: 0,
        questionnaireListData: action.payload
      }
    case types.SET_RESULT_LIST:
      const { reportData, resultListData, headingInformationData, resultString, isError } = action.payload;
      const message = action.payload.welcomeMessage;
      const showMessage = action.payload.showWelcomeMessage;
      return {
        ...state,
        welcomeMessage: isNotEmpty(message) ? message : '',//show welcome message to user
        showWelcomeMessage: isNotEmpty(showMessage) ? showMessage : false,
        isError: isError,
        resultsListData: resultListData,
        headingInformationData: headingInformationData,
        resultString: resultString,
        reportData: reportData
      }
    case types.SET_CALCULATOR_DATA:
      const { questionListData, resultData, showWelcomeMessage, welcomeMessage } = action.payload;
      return {
        ...state,
        welcomeMessage: welcomeMessage,//show welcome message to user
        showWelcomeMessage: showWelcomeMessage,
        activeRoute: 'Questionnaire',
        showCalculatorDetails: false,
        isError: resultData.isError,
        questionKey: Math.floor(100000 + Math.random() * 900000),
        activeItemIndex: 0,
        activeItemIndexHeaderIndecator: 0,
        questionnaireListData: questionListData,
        resultsListData: resultData.resultListData,
        headingInformationData: resultData.headingInformationData,
        resultString: resultData.resultString,
        reportData: action.payload.reportData
      }
    case types.ACTIVE_INDECATOR:
      return {
        ...state,
        activeItemIndex: action.payload
      }

    case types.UPDATE_CONVERSATION_INPUTS:
      return {
        ...state,
        InputList: action.payload
      }

    case types.INPUT_KEY_PRESS_COUNT:
      return {
        ...state,
        inputKeyPressCount: action.payload
      }

    case types.SHOW_CALCULATOR_DETAILS_POPUP:
      return {
        ...state,
        showCalculatorDetails: true
      }
    case types.HIDE_CALCULATOR_DETAILS_POPUP:
      return {
        ...state,
        showCalculatorDetails: false
      }
    case types.TOGGLE_LINK_SHARE_POPUP:
      return {
        ...state,
        showLinkShare: action.payload
      }
    case types.TOGGLE_APP_MENU:
      return {
        ...state,
        showAppMenu: action.payload
      }

    case types.ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
        activeResultItem: {},
        showResult: false
      }

    case types.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    case types.SET_ACTIVE_RESULT_ITEM:
      const { activeResultItem, showResult } = action.payload;
      return {
        ...state,
        activeResultItem: activeResultItem,
        showResult: showResult
      }
    case types.SET_CONVERSION_DATA:
      return {
        ...state,
        isError: false,
        InputList: action.payload.InputList,
        resultString: action.payload.resultString,
      }

    case types.SHOW_CONTACT_US:
      return {
        ...state,
        showContactUs: action.payload
      }

    case types.SHOW_ABOUT_US:
      return {
        ...state,
        showAboutUs: action.payload
      }
  
      case types.SHOW_PRIVACY:
      return {
        ...state,
        showPrivacy: action.payload
      }

    case types.SHOW_EMAIL_DIALOG:
      return {
        ...state,
        showEmailDialog: action.payload
      }

    case types.SHOW_PDF_PREVIEW_DIALOG:
      return {
        ...state,
        showPdfPreviewDialog: action.payload
      }

    case types.SET_USER_NAME_AND_EMAIL:
      const { name, email } = action.payload;
      return {
        ...state,
        name: name,
        email: email
      }
    default:
      return state
  }
}

