import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import appReducer from "./appReducer";

// import auth from "./auth";
// export default combineReducers({
//   appReducer: appReducer,
//   auth: auth,
// });

export default (history) =>
  combineReducers({
    appReducer,
    router: connectRouter(history),
  });
