import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from 'react-loadable';
import ProgressLoader from './common/progressLoader';

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './components/home/homeWeb'),
  loading: () => <ProgressLoader isLoading={true} />,
});

const Categories = Loadable({
  loader: () => import(/* webpackChunkName: "categories" */ './components/relatedCalculators/relatedCalculatorWeb'),
  loading: () => <ProgressLoader isLoading={true} />,
});

const Layout = Loadable({
  loader: () => import(/* webpackChunkName: "layout" */ './components/layout/layoutWeb'),
  loading: () => <ProgressLoader isLoading={true} />,
});

export default () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/:calcType" component={Categories} />
    <Route exact path="/:calcType/:calcId" component={Layout} />
    <Redirect to="/" />
  </Switch>
);


