// The basics
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Offline } from "react-detect-offline";
import { isTablet } from './utilities/Utils';
import Routes from './routesMob';
import '../src/assets/CSS/mob/home.css';
import '../src/assets/CSS/mob/leftNavMenu.css';
import '../src/assets/CSS/mob/expandableList.css';
import { ConnectionErrorDialog } from './components/connectionError';
import 'react-light-accordion/demo/css/index.css';

class AppMob extends Component {

    constructor() {
        super();
        this.onRefresh = this.onRefresh.bind(this);
    }

    onRefresh = () => {
        window.location.reload();
    }

    componentDidMount() {
        document.body.style.position = "fixed";

        document.documentElement.addEventListener('touchmove', (event) => {
            // event.preventDefault();
        }, false)

        document.addEventListener("gesturestart", function (e) {
            e.preventDefault();
            document.body.style.zoom = 0;
        })

        document.addEventListener("gesturechange", function (e) {
            e.preventDefault();
            document.body.style.zoom = 0;
        }.bind(this));

        document.addEventListener("gestureend", function (e) {
            e.preventDefault();
            document.body.style.zoom = 0;
        }.bind(this));
    }
    render() {
        return (
            <div id="app" className={`${!isTablet() && 'portrait-mode'}`}>
                <Routes />
                <Offline>
                    <ConnectionErrorDialog
                        isOpen={true}
                        onRefresh={this.onRefresh}
                        onClose={this.onSnackbarClose}
                    />
                </Offline>
            </div>
        );
    }
}
export default withRouter(AppMob);
