export const AUTHENTICATE = 'AUTHENTICATE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

export const TOGGLE_LINK_SHARE_POPUP = 'TOGGLE_LINK_SHARE_POPUP';
export const TOGGLE_APP_MENU = 'TOGGLE_APP_MENU';
export const IS_LOADING = 'IS_LOADING';

export const UPDATE_QUESTIONNAIRES = 'UPDATE_QUESTIONNAIRES';
export const TOGGLE_RESULTS_LIST_ITEM = 'TOGGLE_RESULTS_LIST_ITEM';
export const SET_CALCULATOR_DATA = 'SET_CALCULATOR_DATA';
export const SET_RESULT_LIST = 'SET_RESULT_LIST';
export const SET_ACTIVE_RESULT_ITEM = 'SET_ACTIVE_RESULT_ITEM';
export const RESET_STATE = 'RESET_STATE';

export const ACTIVE_ROUTE = 'ACTIVE_ROUTE';
export const ACTIVE_INDECATOR = 'ACTIVE_INDECATOR';
export const ACTIVE_INDECATOR_HEADER = 'ACTIVE_INDECATOR_HEADER';
export const SHOW_CALCULATOR_DETAILS_POPUP = 'SHOW_CALCULATOR_DETAILS_POPUP';
export const HIDE_CALCULATOR_DETAILS_POPUP = 'HIDE_CALCULATOR_DETAILS_POPUP';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_CONVERSION_DATA = 'SET_CONVERSION_DATA';
export const SHOW_CONTACT_US = 'SHOW_CONTACT_US';
export const SHOW_ABOUT_US = 'SHOW_ABOUT_US';
export const SHOW_PRIVACY = 'SHOW_PRIVACY';

export const UPDATE_CONVERSATION_INPUTS = 'UPDATE_CONVERSATION_INPUTS';
export const INPUT_KEY_PRESS_COUNT = 'INPUT_KEY_PRESS_COUNT';

//pdf 
export const SHOW_EMAIL_DIALOG = 'SHOW_EMAIL_DIALOG';
export const SHOW_PDF_PREVIEW_DIALOG = 'SHOW_PDF_PREVIEW_DIALOG';
export const SET_USER_NAME_AND_EMAIL = 'SET_USER_NAME_AND_EMAIL';
