// The basics
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Offline } from 'react-detect-offline';

import Routes from './routes';
import shareThis from 'share-this/dist/share-this';
import * as twitterSharer from 'share-this/dist/sharers/twitter';
import * as facebookSharer from 'share-this/dist/sharers/facebook';
import * as linkedInSharer from 'share-this/dist/sharers/linked-in';
import * as redditSharer from 'share-this/dist/sharers/reddit';
import * as emailSharer from 'share-this/dist/sharers/email';
import { ConnectionErrorDialog } from './components/connectionError';
import 'react-light-accordion/demo/css/index.css';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  onRefresh = () => window.location.reload();

  componentDidMount() {
    const selectionShare = shareThis({
      selector: '#shareable',
      sharers: [
        facebookSharer,
        twitterSharer,
        linkedInSharer,
        redditSharer,
        emailSharer,
      ],
    });
    selectionShare.init();

    window.addEventListener('offline', (e) => this.setState({ open: true }));
    window.addEventListener('online', (e) =>
      setTimeout(this.setState({ open: false }), 5000)
    );
  }

  render() {
    return (
      <div id="app">
        <Routes />
        <Offline>
          <ConnectionErrorDialog
            isOpen={true}
            onRefresh={() => this.onRefresh()}
            onClose={this.onSnackbarClose}
          />
        </Offline>
      </div>
    );
  }
}
export default withRouter(App);
