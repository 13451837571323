import { isMobile, isTablet as isIosTablet,isIPad13, isBrowser as webBrowser } from "react-device-detect";

import { InputTypes } from "./TypesEnums";
import { calcIds } from "./calcIdEnums";

export const getUrlPerms = (location) => {
    const calcID = location.pathname.split("/");
    return {
        calcType: calcID[1],
        calcId: calcID[2]
    }
}

export const scrollTop = () => {
    try {
        window.screenTop(0, 0);
    } catch (err) {
        //console.log('screenTop', 'window is not defined');
    }
}

export const isValidEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const isEmpty = (data) => {
    return data === undefined || data === "undefined" || data === null || data.length === 0 ? true : false;
}

export const isNotEmpty = (data) => {
    return data === undefined || data === "undefined" || data === null || data.length === 0 ? false : true
}

export const hasChild = (data) => {
    return (isNotEmpty(data) && data.length > 1)
}

export const isCurrency = (value) => {
    if (isNotEmpty(value)) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const getGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const IsMobile = () => {
    return isMobile
}

export const isIos13IPad = () => {
    try {
        return /iPad|iPhone|iPod/.test(navigator.platform) && (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    } catch (error) 
    
    {
        return false;
    }
}

export const isTablet = () => {
    return isIosTablet || isIPad13;
}

export const isBrowser = () => {
    return webBrowser && !isIPad13;
}

// get query perms from url 
export const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const getTitle = (title) => {
    if (isNotEmpty(title)) {
        title = title.replace(/([A-Z])/g, ' $1').trim();
        title = title.replace(/-/g, ' ');
        title = title.replace(/_/g, ' ');
        return title;
    } else {
        return '';
    }
}

export const getGetOrdinal = (n) => {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const convertToDecimals = (number) => {
    var precision = 2;
    var shift = function (number, precision, reverseShift) {
        if (reverseShift) {
            precision = -precision;
        }
        var numArray = ("" + number).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
    };
    return shift(Math.round(shift(number, precision, false)), precision, true);
}

export const dollars = (val) => {
    var isNegative = val < 0 ? '-' : '';
    var fixValue = Math.abs(val.toFixed(0));
    return isNegative + '$' + fixValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const labelDollars = (val) => {
    var isNegative = val < 0 ? '-' : '';
    return isNegative + '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const round = (b, a) => {
    return (a ? (Math.round(b * (Math.pow(10, a)))) / (Math.pow(10, a)) : Math.round(b))
}

export const isPhpCalculator = (calcType) => {
    switch (calcType) {
        case "health":
        case "math":
        case "pregnancy":
        case "other":
        case "lifestyle":
            return true
        default:
            return false
    }
}

export const isConversionCalculator = (calcType) => {
    switch (calcType) {
        case "conversions":
        case "physics":
        case "thermodynamics":
            return true
        default:
            return false
    }
}

export const getConversionOptions = (obj) => {
    var optionArray = [];
    optionArray = obj.map((data) => {
        var options = {
            text: `${isNotEmpty(data.unit) ? data.unit : ''} (${data.name})`,
            value: `${data.name}`,
            showTooltip: data.showTooltip,
            toolTip: data.toolTip,
        };
        return options;
    });
    return optionArray;
}

// validate welcome message
export const checkValidMessage = (props, calculatorInputs, callbak) => {
    const { appState, appActions, location } = props;
    const { calcId } = getUrlPerms(location);

    let inputKeyPress = 0;
    let inputs = [];

    calculatorInputs.map(obj => {
        const inputType = obj.inputType;
        switch (inputType) {
            case InputTypes.number:
            case InputTypes.numberComma:
            case InputTypes.numberDollar:
            case InputTypes.numberPercent:
            case InputTypes.numberMonths:
                const value = obj.value;
                if (isNumber(value)) {
                    if (value > 0) {
                        inputs.push(value);
                        inputKeyPress = inputKeyPress + 1;
                        // appActions.keyPressCount(inputKeyPress);
                    }
                }
                break;
            default:
                break;
        }
    });
    let welcomeMessage = inputs.length > 0 ? 'Just a few more questions' : 'Lets get started';
    const showWelcomeMessage = showMessage(inputKeyPress, calcId);
    callbak({
        resultListData: {
            chartListData: [],
            gridListData: []
        },
        headingInformationData: [],
        resultString: welcomeMessage,
        isError: showWelcomeMessage,
        showWelcomeMessage: showWelcomeMessage,
        welcomeMessage: welcomeMessage
    })
}

function showMessage(inputKeyPress, calcId) {
    switch (calcId) {
        case calcIds.profitMargin:
        case calcIds.individual401kContribution:
        case calcIds.hSAContribution:
        case calcIds.presentValue:
        case calcIds.mortgageHomebuyerSavings:
        case calcIds.ArmyBodyFatCalc:
        case calcIds.annualReturnAmount:
        case calcIds.futureValueAmount:
        case calcIds.earnedIncomeCredit:
            return inputKeyPress <= 1
        case calcIds.presentValueAmount:
            return inputKeyPress < 1
        case calcIds.taxEZ1040:
        case calcIds.taxSelfEmployment:
            return false
        case calcIds.mortgageRefinance:
        case calcIds.fHAMortgageQualifier:
        case calcIds.bizBuyvsLease:
            return inputKeyPress <= 3
        default:
            return inputKeyPress <= 2
    }
}


function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return k;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return -1.
            return -1;
        },
        configurable: true,
        writable: true
    });
}

if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {

                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}


