import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from 'react-loadable';
import ProgressLoader from './common/progressLoader';

const HomeMob = Loadable({
    loader: () => import(/* webpackChunkName: "homeMob" */ './components/home/homeMob'),
    loading: () => <ProgressLoader isLoading={true} />,
});

const SearchMob = Loadable({
    loader: () => import(/* webpackChunkName: "searchMob" */ './components/search/searchMob'),
    loading: () => <ProgressLoader isLoading={true} />,
});

const CategoriesMob = Loadable({
    loader: () => import(/* webpackChunkName: "categoriesMob" */ './components/relatedCalculators/relatedCalculatorMob'),
    loading: () => <ProgressLoader isLoading={true} />,
});

const LayoutMob = Loadable({
    loader: () => import(/* webpackChunkName: "layoutMob" */ './components/layout/layoutMob'),
    loading: () => <ProgressLoader isLoading={true} />,
});

export default () => (
    <Switch>
        <Route exact path="/" component={HomeMob} />
        <Route exact path="/search" component={SearchMob} />
        <Route exact path="/:calcType" component={CategoriesMob} />
        <Route exact path="/:calcType/:calcId" component={LayoutMob} />
        <Redirect to="/" />
    </Switch>
);


